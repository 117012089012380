<template>
  <div class="homeIndexBanner" :style="{ 'padding-top': paddingTop }">
    <div class="bannerContent">
      <el-carousel
        dots="none"
        trigger="click"
        :arrow="dataList.length > 1 ? 'hover' : 'never'"
        :indicator-position="dataList.length > 1 ? '' : 'none'"
      >
        <el-carousel-item v-for="(item, index) in dataList" :key="index">
          <div
            class="bannerInner"
            :style="{ 'background-image': 'url(' + item.url + ')' }"
            :class="{
              pointer: item.link_url && item.extend && !item.extend.is_show,
            }"
          >
            <div
              class="bannerHerf"
              v-if="item.link_url && item.extend && !item.extend.is_show"
            >
              <a
                :href="item.link_url"
                target="_blank"
                rel="nofollow"
                v-if="item.link_url && item.link_url.indexOf('http') == '0'"
              ></a>
              <a :href="item.link_url" rel="nofollow" v-else></a>
            </div>
            <div
              v-if="item.extend && item.extend.is_show"
              class="bannerBtn"
              :class="'btnStyle' + item.extend.buttonStyle"
              :style="{
                bottom: `${item.extend.bottom}%`,
                right: `${item.extend.right}%`,
                background: item.extend.backgroundColor,
                borderColor: item.extend.borderColor,
              }"
            >
              <!-- @click="handleLink(item.link_url)" -->
              <!-- <el-button type="text"> -->
                <a
                  :style="{
                    fontSize: `${item.extend.remSize}px`,
                    color: item.extend.color,
                  }"
                  :href="item.link_url"
                  target="_blank"
                  rel="nofollow"
                  v-if="item.link_url && item.link_url.indexOf('http') == '0'"
                >
                  {{ item.extend.content }}</a
                >
                <a
                  :href="item.link_url"
                  rel="nofollow"
                  :style="{
                    fontSize: `${item.extend.remSize}px`,
                    color: item.extend.color,
                  }"
                  v-else
                >
                  {{ item.extend.content }}</a
                >
              <!-- </el-button> -->
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="banner_search" v-if="PJSource === 20 && showSearch">
        <div class="search" v-if="select != 3">
          <el-autocomplete
            v-model="search_value"
            :fetch-suggestions="querySearchCompanyName"
            :placeholder="placeHoleder"
            :trigger-on-focus="false"
            class="s_input_sty"
          >
            <el-select
              v-model="select"
              class="s_select"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="搜成员" value="1"></el-option>
              <el-option label="搜活动" value="2"></el-option>
              <el-option label="搜职位" value="3"></el-option>
              <el-option label="搜课程" value="4"></el-option>
            </el-select>
            <el-button slot="append" class="btn_sty" @click="searchContent">
              <i class="el-icon-search"></i>
              <span>搜索</span>
            </el-button>
          </el-autocomplete>
        </div>
        <div v-else class="search">
          <el-input
            v-if="select == 3"
            v-model="search_value"
            :placeholder="placeHoleder"
            class="s_input_sty"
          >
            <el-select
              v-model="select"
              class="s_select"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="搜成员" value="1"></el-option>
              <el-option label="搜活动" value="2"></el-option>
              <el-option label="搜职位" value="3"></el-option>
              <el-option label="搜课程" value="4"></el-option>
            </el-select>
            <el-button slot="append" class="btn_sty" @click="searchContent">
              <i class="el-icon-search"></i>
              <span>搜索</span>
            </el-button>
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "homeIndexBanner",
  props: {
    //盒子撑高百分比
    paddingTop: {
      type: String,
      default: "24%",
    },
    //banner类型
    bannerType: {
      type: Number,
      default: 0,
    },
    //gbk首页搜索框传1
    showSearch: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dataList: [],
      select: "1",
      placeHoleder: "请输入成员名称",
      search_value: "",
      zhReg: /^[\u4e00-\u9fa5]+$/i,
    };
  },
  watch: {
    select: function (value) {
      this.search_value = "";
      switch (value) {
        case "1":
          this.placeHoleder = "请输入成员名称";
          break;
        case "2":
          this.placeHoleder = "请输入活动主题";
          break;
        case "3":
          this.placeHoleder = "请输入职位名称";
          break;
        case "4":
          this.placeHoleder = "请输入课程名称";
          break;

        default:
          break;
      }
    },
  },
  created() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      let params = {
        language: this.LOCALE === "en" ? 1 : 2,
        source: this.PJSource,
        banner_type: this.bannerType,
      };
      // 获取协会的 banner 部分
      let res = await this.$store.dispatch(
        "baseStore/getAssociationBanner",
        params
      );
      // console.log(res);
      if (res.success) {
        res.data.forEach((item) => {
          item.extend = JSON.parse(item.extend);
          item.extend.remSize = this.rootResize(item.extend.fontSize);
        });
        this.dataList = res.data;
      }
      // console.log(this.dataList);
    },
    rootResize(val) {
      var baseFontSize = val || 14;
      var baseWidth = 1920;
      var minWidth = 1200;
      var clientWidth =
        document.documentElement.clientWidth || window.innerWidth;
      var innerWidth = Math.max(Math.min(clientWidth, baseWidth), minWidth);
      var rem = clientWidth / (baseWidth / baseFontSize);
      rem = Math.round(innerWidth / (baseWidth / baseFontSize));
      return rem;
    },
    handleLink(link) {
      if (link.indexOf("http") == "0") {
        window.open(link);
      } else {
        this.$router.push(link);
      }
    },
    handleDivLink(item) {
      if (item.link_url && item.extend && !item.extend.is_show) {
        this.handleLink(item.link_url);
      }
    },
    querySearchCompanyName(queryString, cb) {
      if (this.select == 1) {
        // 搜成员
        this.searchUser(queryString, cb);
      } else if (this.select == 2) {
        //搜活动
        this.searchActivity(queryString, cb);
      } else if (this.select == 3) {
        //搜职位
        this.searchJob(queryString, cb);
      } else if (this.select == 4) {
        //搜课程
        this.searchKC(queryString, cb);
      }
    },
    // 搜成员
    async searchUser(queryString, cb) {
      let params = {};
      params.company_name = queryString;
      params.source = this.PJSource;
      params.usage = 2;
      params.is_vip = 1;
      let res = await this.$store.dispatch(
        "baseStore/company_associateSearch",
        params
      );
      let values;
      if (this.zhReg.test(queryString)) {
        values = res.data.map((item) => {
          item.value = item.name_zh;
          return item;
        });
      } else {
        // 输入是英文或者是数字
        values = res.data.map((item) => {
          if (item.name_en) {
            // 返回数据中，英文有值
            item.value = item.name_en;
          } else {
            item.value = item.name_zh;
          }
          return item;
        });
      }
      cb(values);
    },
    //搜活动
    async searchActivity(queryString, cb) {
      let params = {};
      params.name = queryString;
      (params.start = 0),
        (params.limit = 10),
        (params.source = this.PJSource),
        (params.user_id = this.USER_INFO.id);
      let res = await this.$store.dispatch(
        "baseStore/baseAssociation_event",
        params
      );
      let values;

      if (this.zhReg.test(queryString)) {
        values = res.data.map((item) => {
          item.value = item.name_zh;
          return item;
        });
      } else {
        // 输入是英文或者是数字
        values = res.data.map((item) => {
          if (item.name_en) {
            // 返回数据中，英文有值
            item.value = item.name_en;
          } else {
            item.value = item.name_zh;
          }
          return item;
        });
      }
      cb(values);
    },
    //搜职位
    async searchJob(queryString, cb) {
      let params = {};
      params.company_name = queryString;
      params.nopage = 0;
      (params.source = this.PJSource), (params.user_id = this.USER_INFO.id);
      let res = await this.$store.dispatch(
        "baseStore/baseGetJobPositionList",
        params
      );
      let values;

      if (this.zhReg.test(queryString)) {
        values = res.data.map((item) => {
          item.value = item.name_zh;
          return item;
        });
      } else {
        // 输入是英文或者是数字
        values = res.data.map((item) => {
          if (item.name_en) {
            // 返回数据中，英文有值
            item.value = item.name_en;
          } else {
            item.value = item.name_zh;
          }
          return item;
        });
      }
      console.log(values);

      cb(values);
    },
    //搜课程
    async searchKC(queryString, cb) {},
    // 搜索框
    searchContent() {
      let url = "";
      if (this.select == 1) {
        // 搜成员
        url = "/companyCatalog";
      } else if (this.select == 2) {
        //搜活动
        url = "/activity";
      } else if (this.select == 3) {
        //搜职位  college
        url = "/positionList";
        if (this.search_value) {
          this.$router.push({
            path: url,
            query: {
              content: this.search_value,
            },
          });
        } else {
          this.$router.push(url);
        }
        return;
      } else if (this.select == 4) {
        //搜课程
        url = "/college";
      }
      if (this.search_value) {
        this.$router.push({
          path: url,
          query: {
            parameter: this._encode({
              searchVal: this.search_value,
            }),
          },
        });
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.homeIndexBanner {
  width: 100%;
  position: relative;
  margin-bottom: 24px;
  .bannerContent {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    .banner_search {
      width: 100%;
      height: 70px;
      position: absolute;
      bottom: 54px;
      z-index: 99;
      .search {
        width: 792px;
        height: 70px;
        border-radius: 8px;
        margin: auto;
        border: 10px solid rgba(255, 255, 255, 0.25);
        /deep/ .s_input_sty {
          width: 100%;
          border-radius: 8px;
          background: #fff;
          height: 50px;
          .el-input__inner {
            margin: 10px 0;
            height: 30px;
            border: 0px;
            border-left: 1px solid #dcdfe6;
          }
          .el-input-group__prepend {
            background: #fff;
          }
          .el-input-group__append {
            border: 0;
            width: 158px;
            height: 50px;
            background: linear-gradient(313deg, #7ec9f1 0%, #0a4dae 100%);
          }
          .s_select {
            width: 105px;
            background: #fff;
            .el-input--suffix {
              background: #fff;
            }
          }
          .btn_sty {
            width: 158px;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              font-size: 20px;
            }
            span {
              height: 25px;
              font-size: 18px;
              font-weight: 800;
              color: #ffffff;
              line-height: 25px;
            }
          }
        }
      }
    }
    .el-carousel {
      height: 100%;
      /deep/ .el-carousel__container {
        height: 100% !important;
        .bannerInner {
          height: 100%;
          background-size: 100% 100%;
          // cursor: pointer;
          position: relative;
          .bannerHerf {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            a {
              display: block;
              height: 100%;
              width: 100%;
            }
          }
          .bannerBtn {
            position: absolute;
            z-index: 20;
            padding: 0 20px;
            border: 1px solid;
            border-radius: 4px;
            transform: translate(50%, 50%);
            a{
              display: block;
              height: 100%;
              width: 100%;
              padding: 12px 0px;
            }
            &.btnStyle1 {
              border-radius: 0px;
            }
            &.btnStyle2 {
              border-radius: 4px;
            }
            &.btnStyle3 {
              border-radius: 999px;
            }
          }
        }
      }
      /deep/ .el-carousel__button {
        width: 40px;
        height: 7px;
        border-radius: 2px;
      }
      /deep/ .el-carousel__arrow {
        width: 50px;
        height: 50px;
        font-size: 45px;
        background: transparent !important;
        i {
          font-weight: bold;
        }
      }
      // /deep/ .el-carousel__indicator.is-active button {
      //   background-color: #ff5155 !important;
      // }
    }
  }
  .pointer {
    cursor: pointer;
  }
}
</style>
