<template>
  <div class="newMembercomponents">
    <div class="cd_title">新加入成员</div>
    <div v-if="affCompanyList.length <= 0">
      <noDataImg></noDataImg>
    </div>
    <div v-loading="loading" style="min-height:200px">
      <div
          v-for="(item, index) in affCompanyList"
          :key="index"
          class="company_memeber"
          v-show="index < num"
      >
        <p>
          <el-image
              :src="item.logo ? item.logo : defaultLogo"
              alt
              style="width: 60px; height: 60px"
              :fit="'scale-down'"
          >
          </el-image>
        </p>
        <div class="overText">
          <p
              class="company_name text_pointer hoverMainColor textOverflow"
              @click="personalPort(item.company_id)"
              :title="
              item.company_name_en
                | priorFormat(item.company_name_cn, LOCALE)
                | textFormat
            "
          >
            {{
              item.company_name_en
                  | priorFormat(item.company_name_cn, LOCALE)
                  |textFormat
            }}
          </p>
          <p
              class="address textOverflow"
              :title="
              item.country
                | countryCityFormat(item.region_en, item.region_zh, LOCALE)
            "
          >
            {{
              item.country
                  | countryCityFormat(item.region_en, item.region_zh, LOCALE)
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newPerson",
  data(){
    return {
      num:3,
      affCompanyList: [],
      loading: false,
      defaultLogo:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/companyLogo.png",
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    async getList(){
      this.loading = true;
      let data = await this.$store.dispatch(
          "API_company/checkNewCompanyList",
          { start: 0, limit: 3,source:this.PJSource }
      );
      this.affCompanyList = data.data;
      this.loading = false;
    },
    //点击名字跳转到个人画像
    personalPort(id) {
      let res=this.$router.resolve({
        path:"/companyDetail",
        query:{
          parameter: this._encode({
            company_id:id
          })
        }
      })
      window.open(res.href, "_blank")
    },
  }
}
</script>

<style scoped lang="less">
.newMembercomponents{
  width: 100%;
  min-height: 272px;
  position: relative;
  margin-bottom: 20px;
  .cd_title {
    font-size: 16px;
    font-weight: 800;
    color: #333333;
  }
  .company_memeber{
    display:flex;
    margin-top: 20px;
    div.overText {
      flex: 1;
      margin-left: 16px;
      word-break: break-word;
      p{
        width: 200px;
      }
      p:nth-last-of-type(1) {
        margin-top: 6px;
      }
    }
    .company_name {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      cursor: pointer;
    }
    .address{
      font-size: 14px;
      font-weight: bold;
      color: #1989FA;
    }
  }
}
</style>
