<template>
  <div>
    <div>
      <div class="bussinessListTitle">{{ $t("bussinessConventional") }}</div>
      <div class="bussinessContent">
        <el-checkbox-group
            v-model="Conventional"
            size="mini"
            @change="change"
            :max="maxOne"
            :min="0"
        >
          <el-checkbox-button
              v-for="city in classification.Convertional"
              :label="city.id"
              :key="city.id"
              size="small"
          >{{
              LOCALE === "en" ? city.desc_en : city.desc_zh
            }}
          </el-checkbox-button
          >
        </el-checkbox-group>
      </div>
    </div>
    <div>
      <div class="bussinessListTitle">{{ $t("bussinessDangerous") }}</div>
      <div class="bussinessContent">
        <el-checkbox-group
            v-model="Dangerous"
            size="mini"
            @change="Dangerouschange"
            :max="maxTwo"
        >
          <el-checkbox-button
              v-for="city in classification['Dangerous Cargo']"
              :label="city.id"
              :key="city.id"
              size="small"
          >{{
              LOCALE === "en" ? city.desc_en : city.desc_zh
            }}
          </el-checkbox-button
          >
        </el-checkbox-group>
      </div>
    </div>
    <div>
      <div class="bussinessListTitle">{{ $t("bussinessParticular") }}</div>
      <div class="bussinessContent">
        <el-checkbox-group
            v-model="Particular"
            size="mini"
            @change="Particularchange"
            :max="maxThree"
        >
          <el-checkbox-button
              v-for="city in classification['Particular Cargo']"
              :label="city.id"
              :key="city.id"
              size="small"
          >{{
              LOCALE === "en" ? city.desc_en : city.desc_zh
            }}
          </el-checkbox-button
          >
        </el-checkbox-group>
      </div>
    </div>
    <div>
      <div class="bussinessListTitle">{{ $t("bussinessServices") }}</div>
      <div class="bussinessContent">
        <el-checkbox-group
            v-model="Services"
            size="mini"
            @change="Serviceschange"
            :max="maxFoure"
        >
          <el-checkbox-button
              v-for="city in classification['Services']"
              :label="city.id"
              :key="city.id"
              size="small"
          >{{
              LOCALE === "en" ? city.desc_en : city.desc_zh
            }}
          </el-checkbox-button
          >
        </el-checkbox-group>
      </div>
    </div>
    <div>
      <div class="bussinessListTitle">{{ $t("Others") }}</div>
      <div class="bussinessContent">
        <el-checkbox-group
            v-model="Other"
            size="mini"
            @change="Otherchange"
            :max="maxFive"
        >
          <el-checkbox-button
              v-for="city in classification['Other convertional']"
              :label="city.id"
              :key="city.id"
              size="small"
          >{{
              LOCALE === "en" ? city.desc_en : city.desc_zh
            }}
          </el-checkbox-button
          >
        </el-checkbox-group>
      </div>
    </div>
    <div class="clearfix">
      <el-button
          type="primary"
          size="small"
          class="fr ml11"
          @click="saveClass"
          :loading="loading"
      >{{ $t("confirm") }}
      </el-button
      >
      <el-button size="small" class="fr" type="text" @click="clickCancel">{{
          $t("cancel")
        }}
      </el-button>
    </div>
  </div>
</template>

<script>
import {businessList} from '@/baseUtils/enumValue'
import {getBusinessObject} from '@/basePlugins/filters'

export default {
  name: "businessClass",
  props: ["isSelect", "number"],
  data() {
    return {
      Conventional: [],
      Dangerous: [],
      Particular: [],
      Services: [],
      Other: [],
      loading: false,
      disabledBtn: false,
      max1: 0,
      max2: 0,
      max3: 0,
      max4: 0,
      max5: 0,
      maxNumber: 0,
      classification: [],
      business_classification: {
        Convertional: [],
        "Dangerous Cargo": [],
        "Other convertional": [],
        "Particular Cargo": [],
        Services: [],
      },
    };
  },
  computed: {
    maxOne() {
      return this.maxNumber - this.max2 - this.max3 - this.max4 - this.max5;
    },
    maxTwo() {
      return this.maxNumber - this.max1 - this.max3 - this.max4 - this.max5;
    },
    maxThree() {
      return this.maxNumber - this.max1 - this.max2 - this.max4 - this.max5;
    },
    maxFoure() {
      return this.maxNumber - this.max1 - this.max2 - this.max3 - this.max5;
    },
    maxFive() {
      return this.maxNumber - this.max1 - this.max2 - this.max3 - this.max4;
    },
  },
  mounted() {
    this.maxNumber = this.number;
    this.classification = getBusinessObject([], true);
    let length;
    if (this.isSelect) {
      length =
          (this.isSelect["Convertional"]
              ? this.isSelect["Convertional"].length
              : 0) +
          (this.isSelect["Dangerous Cargo"]
              ? this.isSelect["Dangerous Cargo"].length
              : 0) +
          (this.isSelect["Particular Cargo"]
              ? this.isSelect["Particular Cargo"].length
              : 0) +
          (this.isSelect["Services"] ? this.isSelect["Services"].length : 0) +
          (this.isSelect["Other convertional"]
              ? this.isSelect["Other convertional"].length
              : 0);
    } else {
      length = 0
    }
    if (length > this.number) {
      this.maxNumber = length;
    }
    this.init();
  },
  watch: {
    isSelect(e) {
      let classification = this.isSelect;
      if (classification["Convertional"]) {
        this.Conventional = classification["Convertional"].map((item) => {
          return item.id;
        });
        this.max1 = this.Conventional.length;
      }
      if (classification["Dangerous Cargo"]) {
        this.Dangerous = classification["Dangerous Cargo"].map((item) => {
          return item.id;
        });
        this.max2 = this.Dangerous.length;
      }
      if (classification["Particular Cargo"]) {
        this.Particular = classification["Particular Cargo"].map((item) => {
          return item.id;
        });
        this.max3 = this.Particular.length;
      }
      if (classification["Services"]) {
        this.Services = classification["Services"].map((item) => {
          return item.id;
        });
        this.max4 = this.Services.length;
      }
      if (classification["Other convertional"]) {
        this.Other = classification["Other convertional"].map((item) => {
          return item.id;
        });
        this.max5 = this.Other.length;
      }
    },
  },
  methods: {
    init() {
      this.$nextTick(() => {
        if(!this.isSelect){
          return
        }
        let classification = this.isSelect;
        if (classification["Convertional"]) {
          this.Conventional = classification["Convertional"].map((item) => {
            return item.id;
          });
          this.max1 = this.Conventional.length;
        }
        if (classification["Dangerous Cargo"]) {
          this.Dangerous = classification["Dangerous Cargo"].map((item) => {
            return item.id;
          });
          this.max2 = this.Dangerous.length;
        }
        if (classification["Particular Cargo"]) {
          this.Particular = classification["Particular Cargo"].map((item) => {
            return item.id;
          });
          this.max3 = this.Particular.length;
        }
        if (classification["Services"]) {
          this.Services = classification["Services"].map((item) => {
            return item.id;
          });
          this.max4 = this.Services.length;
        }
        if (classification["Other convertional"]) {
          this.Other = classification["Other convertional"].map((item) => {
            return item.id;
          });
          this.max5 = this.Other.length;
        }
      });
    },
    change(e) {
      this.max1 = e.length;
      this.resetMaxNumber();
    },
    Dangerouschange(e) {
      this.max2 = e.length;
      this.resetMaxNumber();
    },
    Particularchange(e) {
      this.max3 = e.length;
      this.resetMaxNumber();
    },
    Serviceschange(e) {
      this.max4 = e.length;
      this.resetMaxNumber();
    },
    Otherchange(e) {
      this.max5 = e.length;
      this.resetMaxNumber();
    },
    resetMaxNumber() {
      let allLength = this.max1 + this.max2 + this.max3 + this.max4 + this.max5;
      if (allLength < this.number + 1) {
        this.maxNumber = this.number;
      } else {
        this.maxNumber = allLength;
      }
    },
    checkLength() {
      let data = this.Conventional.concat(this.Dangerous)
          .concat(this.Particular)
          .concat(this.Services)
          .concat(this.Other);
      return data;
    },
    checkAll() {
      let classification = this.classification;

      this.Conventional = classification["Convertional"].map((item) => {
        return item.id;
      });
      this.Dangerous = classification["Dangerous Cargo"].map((item) => {
        return item.id;
      });
      this.Particular = classification["Particular Cargo"].map((item) => {
        return item.id;
      });
      this.Services = classification["Services"].map((item) => {
        return item.id;
      });
      this.Other = classification["Other convertional"].map((item) => {
        return item.id;
      });
    },
    clearCheck() {
      this.Conventional = [];
      this.Dangerous = [];
      this.Particular = [];
      this.Services = [];
      this.Other = [];
      this.max1 = 0;
      this.max2 = 0;
      this.max3 = 0;
      this.max4 = 0;
      this.max5 = 0;
    },
    clickCancel() {
      this.$emit("closeClass");
    },
    saveClass() {
      let data = this.Conventional.concat(this.Dangerous)
          .concat(this.Particular)
          .concat(this.Services)
          .concat(this.Other);
      if (this.number === 5) {
        if (data.length > 5) {
          this.$message({
            showClose: true,
            message: this.LOCALE === "en" ? "Select up to five" : "最多选择五个",
            type: "warning",
          });
          return;
        }
      }

      this.$emit("saveClass", data);
    },
  }
}
</script>

<style scoped lang="less">
.bussinessListTitle {
  line-height: 26px;
  border-bottom: 1px solid #f2f2f2;
}

.bussinessContent {
  padding: 15px 0 5px;
}

.ml11 {
  margin-left: 11px;
}
</style>